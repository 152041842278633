


















import { Vue, Component, Prop } from 'vue-property-decorator'
import VueUploadComponent from 'vue-upload-component'

@Component({
  components: { FileUpload: VueUploadComponent }
})
export default class Uploadbox extends Vue {
  @Prop() label!: string
  @Prop() name!: string
  @Prop() endpoint!: string
  @Prop() allowed!: Array<string>

  files = []
  showError = false;
  description = this.label

  inputFile (newFile: any, oldFile: any) {
    if (newFile && oldFile && !newFile.active && oldFile.active) {
      console.log(newFile.response)
      this.$emit('file', newFile.response.name)
    }
  }

  /**
   * Pretreatment
   * @return undefined
   * @param newFile
   * @param oldFile
   * @param prevent
   */
  inputFilter (newFile: any, oldFile: any, prevent: Function) {
    if (newFile && !oldFile) {
      // Filter non-image file
      if (!/\.(mp3)$/i.test(newFile.name)) {
        this.showError = true
        return prevent()
      }
    }

    // Create a blob field
    newFile.blob = ''
    const URL = window.URL || window.webkitURL
    if (URL && URL.createObjectURL) {
      newFile.blob = URL.createObjectURL(newFile.file)
    }

    this.description = newFile.name + ' uploaded'
    this.showError = false
  }
}
