




























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Textbox extends Vue {
  @Prop() label!: string
  @Prop() name!: string
  @Prop({ default: 'text' }) type!: string
  @Prop() value!: string
  @Prop() placeholder!: string
  @Prop() description!: string
  @Prop() maxLength!: number
  @Prop({ default: '' }) validation!: string
}

