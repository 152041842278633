





import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Viewport extends Vue {
  @Prop() id!: string;

  inViewPort = false;

  mounted () {
    this.detectInViewPort()
  }

  isInViewPort (el: Element) {
    const rect = el.getBoundingClientRect()

    return (
      rect.top >= -100 &&
      rect.left >= -100 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    )
  }

  detectInViewPort () {
    document.addEventListener('scroll', () => this.handleViewPortDetection())
  }

  handleViewPortDetection () {
    const element = document.getElementById(this.id)
    if (this.isInViewPort(element)) {
      this.inViewPort = true
      this.$emit('viewport', this.inViewPort)
    }
  }
}
