









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Radio extends Vue {
  @Prop() value!: any
  @Prop() name!: string
  @Prop() label!: string

  select () {
    this.$emit('input', this.name)
  }
}
