var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded rounded-lg bg-white shadow shadow-xs border border-gray"},[(_vm.header)?_c('div',{staticClass:"p-5 font-bold rounded-lg rounded-b-none",class:{
        'bg-black': (_vm.variant === 'normal'),
        'bg-white': (_vm.variant === 'light'),
        'text-white': (_vm.variant === 'normal'),
        'text-black': (_vm.variant === 'light'),
        'border-b': (_vm.variant === 'light'),
        'border-gray': (_vm.variant === 'light')
      }},[_vm._t("header")],2):_vm._e(),_c('div',{class:{'p-6': this.padding }},[_vm._t("default"),_vm._t("content"),(!this.padding)?_c('div',{staticClass:"h-3 border-t border-gray-light rounded-b-md"},[_vm._t("footer")],2):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }