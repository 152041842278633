





import { Component, Prop, Vue } from 'vue-property-decorator'
import { Http } from '../../classes/Http'

declare let window: any

@Component
export default class HttpDelete extends Vue {
  @Prop({ default: '' }) url!: string;
  @Prop() body!: FormData

  static SUCCESS = 1;
  static FAILED = 0;

  isFetching = false;
  status = HttpDelete.SUCCESS;

  data = {}

  http = new Http({
    success: () => this.$emit('success', true),
    failed: () => this.$emit('success', false),
    unauthorized: () => {
      window.location.href = '/login'
    }
  })

  delete () {
    this.isFetching = true

    this.$emit('fetch', { isFetching: this.isFetching, data: null })

    return this.http.delete(this.url)
      .then((data: object) => {
        this.isFetching = false
        this.$forceUpdate()
        this.$emit('fetch', { isFetching: this.isFetching, data: data })
      })
  }
}
