import Vue from 'vue'
import Vuex from 'vuex'

import { Translations } from '@/store/translations'
import { Languages } from '@/store/languages'
import { Texts } from '@/store/texts'
import { Campaigns } from '@/store/campaigns'
import { Geos } from '@/store/geos'
import { Topics } from '@/store/topics'
import { Audiences } from '@/store/audiences'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ctas: { data: [] },
    descriptions: { data: [] },
    texts: { data: [] }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    translations: Translations,
    languages: Languages,
    texts: Texts,
    campaigns: Campaigns,
    geos: Geos,
    audiences: Audiences,
    topics: Topics
  }
})
