

















import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { DateRange } from '../../../interfaces'
import Button from '../../../components/form/Button.vue'
import { format, addDays, subDays, differenceInDays } from 'date-fns'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Card from '../../../components/base/Card.vue'
import { DropdownBehaviour } from '../../../mixins/DropdownBehaviour'

@Component({
  components: { Button, Calendar, DatePicker, Card },
  methods: { format: format }
})
export default class DateRangeField extends Mixins(DropdownBehaviour) {
  @Prop() value!: DateRange

  isOpen = false

  range = {
    start: new Date(this.value.start),
    end: new Date(this.value.end)
  }

  @Watch('range', { deep: true })
  emitRange () {
    this.$emit('input', { start: this.range.start, end: this.range.end })
  }

  next () {
    const diff = Math.abs(differenceInDays(new Date(this.range.start), new Date(this.range.end)))

    this.range = { start: addDays(new Date(this.range.start), diff), end: addDays(new Date(this.range.end), diff) }
  }

  prev () {
    const diff = Math.abs(differenceInDays(new Date(this.range.start), new Date(this.range.end)))

    this.range = { start: subDays(new Date(this.range.start), diff), end: subDays(new Date(this.range.end), diff) }
  }
}
