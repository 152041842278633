import { Vue, Component } from 'vue-property-decorator'

@Component
export class Gate extends Vue {
  mounted () {
    if (!Gate.isAdmin()) {
      document.dispatchEvent(new CustomEvent('unauthorized', { detail: true }))
    }
  }

  static isAdmin () {
    const localUser = JSON.parse(localStorage.getItem('user'))

    return (localUser.role === 1)
  }
}
