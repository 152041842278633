








import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Radio from './Radio.vue'

@Component({ components: { Radio } })
export default class RadioGroup extends Vue {
  @Prop() items: Array<string>
  @Prop({ default: '' }) validation: string
  @Prop() value!: any

  selected = this.value

  @Watch('selected')
  select () {
    this.$emit('input', this.selected)
  }
}
