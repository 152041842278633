
































import { Vue, Component, Prop } from 'vue-property-decorator'
import Searchbox from '../../form/Searchbox.vue'
import Label from '../../base/Label.vue'
import SubTitle from '../../base/SubTitle.vue'
import Badge from '../../base/Badge.vue'
import HttpGet from '../../logic/HttpGet.vue'

interface Result {
  id: number;
}

@Component({
  components: { HttpGet, SubTitle, Searchbox, Label, Badge }
})
export default class SearchSelect extends Vue {
  @Prop() label!: string
  @Prop() title!: string
  @Prop() url!: string
  @Prop({ default: '' }) validation!: string
  @Prop({ default: '' }) tagUrl!: string
  @Prop() value!: Array<object>

  search = []
  tags = []
  searchTerm = ''

  selected = this.value

  select (result) {
    const current = this.selected.find((res: Result) => res.id === result.id)

    if (current === undefined) {
      this.selected = this.selected.concat(result)
    }

    this.$emit('input', this.selected)
  }

  remove (result) {
    this.selected = this.selected.filter((res: Result) => res.id !== result.id)
    this.$emit('input', this.selected)
  }
}
