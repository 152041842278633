


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Sidebar extends Vue {
  @Prop() logoPath!: string

  isOpen = true

  mounted () {
    (window.innerWidth <= 640) ? this.isOpen = false : this.isOpen = true
  }

  @Watch('isOpen')
  handleOpen () {
    this.$emit('open', this.isOpen)
  }
}
