





import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Lever extends Vue {
  @Prop() value!: boolean
  @Prop({ default: 'bg-primary' }) onColor!: string;
  @Prop({ default: 'bg-gray' }) offColor!: string;

  isOn = this.value

  @Watch('isOn')
  handleSwitch () {
    this.$emit('input', this.isOn)
  }
}
