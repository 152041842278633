import Vue from 'vue'

export const Form = Vue.extend({
  methods: {
    handleSuccess (success: boolean) {
      this.isSuccessFull = success

      if (this.isSuccessFull) {
        this.$emit('success')
        this.$router.push(this.redirect)
      }

      this.$refs.validation.$forceUpdate()
      this.$refs.submit.$forceUpdate()
    }
  }
})
