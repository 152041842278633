import Vue from 'vue'
import { NotifiableType } from '../interfaces/Notifiable'
import { Notifier } from '../classes/Notifier'
import { Component } from 'vue-property-decorator'

@Component
export class Clip extends Vue {
    isRefreshing = false
    message = ''
    description = ''

    clipboard (data: string) {
      const copyInput = document.createElement('textarea')
      const value = data

      copyInput.style.position = 'absolute'
      copyInput.style.left = '-1000px'
      copyInput.style.top = '-1000px'

      copyInput.value = value
      document.body.appendChild(copyInput)
      copyInput.select()
      document.execCommand('copy')
      document.body.removeChild(copyInput)

      Notifier.send({
        message: this.message,
        description: this.description,
        type: NotifiableType.Message
      })
    }
}
