

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DropdownBehaviour } from '../../mixins/DropdownBehaviour'
import { Option } from '../../interfaces/Option'
import Searchbox from './Searchbox.vue'
import HttpGet from '../logic/HttpGet.vue'

@Component({
  components: { Searchbox, HttpGet },
  mixins: [DropdownBehaviour]
})
export default class SearchDropdown extends Vue {
  @Prop() label!: string
  @Prop() name!: string
  @Prop() url!: string
  @Prop({ default: true }) bottom: boolean
  @Prop({ default: { label: 'No Language', value: 0 } }) value!: Option | undefined

  search = ''
  isOpen = false
  options = { data: [] }
  selected: Option | undefined = this.value

  select (selectedItem: any) {
    this.selected = this.options.data.find(item => item.value === selectedItem.value)
    this.isOpen = false

    this.$emit('input', this.selected)
  }

  @Watch('search')
  watchSearch () {
    this.$emit('search', this.search)
  }
}
