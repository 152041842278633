










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class OverlayLink extends Vue {
  @Prop() wrapper!: string
  @Prop() link!: string
  @Prop() icon!: string
}
