








import { Vue, Component, Prop } from 'vue-property-decorator'
import LoaderIcon from '../svg/LoaderIcon.vue'

@Component({
  components: { LoaderIcon }
})
export default class Loader extends Vue {
  @Prop({ default: false }) small!: boolean
  @Prop({ default: 'Loading' }) label!: string
  @Prop({ default: '' }) loader!: string;
}
