/**
 *
 * @param obj
 * @param key
 */
export const s = (obj: object | undefined, key: string): any => {
  return obj === undefined ? '' : obj[key]
}

/**
 *
 * @param variable
 */
export const isValid = (variable: any): boolean => {
  return variable !== null && variable !== undefined
}

/**
 *
 * @param index
 * @param keys
 */
export const headerToKey = (index: number, keys: Array<string>): string => {
  return keys[index]
}
