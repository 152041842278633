









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ModalHeader extends Vue {
  @Prop() title!: string;
}
