



















import { Component, Vue } from 'vue-property-decorator'
import Sidebar from '../components/sidebar/Sidebar.vue'
import SidebarItem from '../components/sidebar/SidebarItem.vue'
import Navbar from '../components/Navbar.vue'
import { NotificationSource, Button } from '../lib/mavejs'

@Component({
  components: { Sidebar, SidebarItem, Button, NotificationSource, Navbar }
})
export default class BaseLayout extends Vue {
  isOpen = true
}
