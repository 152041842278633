



import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop() icon!: string;
}
