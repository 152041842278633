








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Check extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop() name!: string
  @Prop() label!: string

  isChecked = this.value

  check () {
    this.isChecked = !this.isChecked
    this.$emit('input', this.isChecked)
  }
}
