import { Change } from '@/interfaces/store/Change'
import { isValid } from '@/lib/mavejs'

export const Translations = {
  state: () => ({
    data: [],
    changed: []
  }),
  mutations: {
    changeTranslations (state, change: Change) {
      const translation = state.changed.find(trans => trans.id === change.id)

      if (!isValid(translation)) {
        state.changed[change.id] = { id: change.id }
      }

      state.changed[change.id][change.data.key] = change.data.value

      state.changed = state.changed.filter(state => (state !== null))
    }
  }
}
