









import { Vue, Component, Prop } from 'vue-property-decorator'
import { Option } from '../../interfaces/Option'

@Component
export default class Dropdown extends Vue {
  @Prop() options!: Array<Option>
  @Prop() value: any;
  @Prop({ default: '' }) validation!: string
  @Prop({ default: 'Not Selected' }) label!: string
  @Prop({ default: false }) dark!: boolean
}
