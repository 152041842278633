import { Notifiable } from '../interfaces/Notifiable'

export abstract class Notifier {
  static send (notification: Notifiable) {
    notification.id = Date.now()

    document.dispatchEvent(new CustomEvent('notification', {
      detail: notification
    }))
  }
}
