













import { Component, Vue } from 'vue-property-decorator'
import { DropdownBehaviour } from '../../mixins/DropdownBehaviour'

@Component({
  mixins: [DropdownBehaviour]
})
export default class ActionMenu extends Vue {
}
