<template>
  <div id="app">
    <BaseLayout v-if="showLayout()">
      <router-view/>
    </BaseLayout>

    <div v-if="!showLayout()">
      <router-view />
    </div>
  </div>
</template>
<script>
import BaseLayout from '@/layouts/BaseLayout'

export default {
  components: { BaseLayout },

  methods: {
    showLayout () {
      if (this.$route.name === null) {
        return true
      }

      if (this.$route.name.includes('Login')) {
        return false
      }

      if (this.$route.name.includes('Not Found')) {
        return false
      }

      return true
    }
  }
}
</script>
