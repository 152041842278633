<template>
  <div class="shadow-md rounded-lg p-6 bg-white w-96">
    <slot></slot>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DropdownMenu extends Vue {

}
</script>
