






import { Vue, Component } from 'vue-property-decorator'
import { Notifiable } from '../../../interfaces/Notifiable'
import Notification from './Notification.vue'

@Component({
  components: { Notification }
})
export default class NotificationSource extends Vue {
  notifications = []

  mounted () {
    document.addEventListener('notification', (event: any) => this.handle(event), false)
  }

  remove (notification: Notifiable) {
    this.notifications = this.notifications.filter((note: Notifiable) => note.id !== notification.id)
  }

  handle (event: any) {
    this.notifications = this.notifications.concat(event.detail)

    setTimeout(() => this.remove(event.detail), 3000)
  }
}
