





























import { Component, Vue, Prop } from 'vue-property-decorator'
import Card from '../base/Card.vue'
import DataTableColumn from './DataTableColumn.vue'
import Paginator from './Paginator.vue'
import Empty from '../base/Empty.vue'
import { headerToKey } from '../../functions'
import { DataTableHead } from '../../interfaces/DataTableHead'

@Component({
  components: {
    Empty,
    DataTableColumn,
    Card,
    Paginator
  },
  methods: { headerToKey: headerToKey }
})
export default class DataTable extends Vue {
  @Prop() headers!: Array<DataTableHead>
  @Prop() store!: Array<object>
  @Prop() pagination!: Array<object>
  @Prop() sorted!: number;
  @Prop() id!: string;

  sortedHead = this.sorted

  sortBy (header: string) {
    this.$emit('sort', header)
  }
}
