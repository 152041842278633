
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'TreeViewItem' })
export default class TreeViewItem extends Vue {
  @Prop() item!: object
  @Prop() name!: string

  isOpen = false

  select (item) {
    document.dispatchEvent(new CustomEvent('treeview:' + this.name + ':selected', {
      detail: item
    }))
  }
}
