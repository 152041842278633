






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DropdownMenuItem extends Vue {
  @Prop() icon!: string
  @Prop() label!: string
  @Prop() to!: string

  open (to) {
    if (this.$route.path !== this.to) {
      this.$router.push(this.to)
    }
  }
}
