<template>
  <i @click="$emit('click', $event.target)" class="material-icons flex items-center justify-center text-sm bg-secondary-lighter text-white rounded-full h-6 w-6 ml-6 p-2 cursor-pointer hover:bg-primary active:bg-primary-dark">edit</i>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Edit extends Vue {
}
</script>
