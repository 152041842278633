declare global {
    interface Window { token: string | null }
}

export default class Token {
  static setToken (token: string) {
    localStorage.setItem('token', token)
  }

  static prepareToken () {
    window.token = localStorage.getItem('token')
  }
}
