






import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Validation extends Vue {
  @Prop() response!: any | undefined
  @Prop() value!: any | undefined
  @Prop() schema!: any | undefined

  validation = this.setFields(this.response)
  timeout = null

  setFields (response: any | undefined) {
    if (response === undefined || response === null || response.errors === undefined) {
      return {}
    }

    const fields = {}

    for (const error in response.errors) {
      fields[error] = response.errors[error][0]
    }

    return fields
  }

  trigger () {
    return this.schema.validate(this.value, { abortEarly: false })
      .then(() => this.$emit('validate', {}))
      .catch((error) => {
        const errors = error.inner
        const errorMapping = {}

        for (const error of errors) {
          errorMapping[error.path] = error.message
        }

        this.$emit('validate', errorMapping)
      })
  }
}
