























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Card extends Vue {
  @Prop({ default: true }) padding!: boolean
  @Prop({ default: true }) header!: boolean
  @Prop({ default: 'normal' }) variant!: string
}
