
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Tooltip extends Vue {
  @Prop({ default: '' }) text!: string

  isOpen = false
}
