import { config } from '@/config'

export const url = (path: string): string => {
  return (process.env.NODE_ENV === 'production') ? config.baseUrl + path : config.baseUrlDev + path
}

export const def = (variable: any, isset: any): any => {
  return (variable === undefined) ? isset : variable
}

export const accs = (variable: any, property: string, isset: any): any => {
  return (variable === undefined || variable[property] === undefined) ? isset : variable[property]
}

export const youtube = (videoId: string): string => {
  if (videoId === undefined) {
    return ''
  }

  if (videoId.includes('http')) {
    return videoId
  }

  return 'https://www.youtube.com/watch?v=' + videoId
}

export const alphabetical = (list: Array<any>, key: string): Array<any>|null => {
  if (list === null) {
    return null
  }

  return list.sort((a, b) => {
    const textA = a[key].toUpperCase()
    const textB = b[key].toUpperCase()
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
  })
}
