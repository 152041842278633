















import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { Clip } from '../../mixins/Clip'
import { Http } from '../../classes/Http'
import Loader from '../../components/base/Loader.vue'
import { HttpResponse } from '../../interfaces/http/Response'

@Component({
  components: { Loader }
})
export default class CopyAsync extends Mixins(Clip) {
  @Prop() url!: string
  @Prop() wrapper!: string
  @Prop() data!: string
  @Prop() label!: string
  @Prop({ default: false }) nested: boolean

  message = 'Copied data successfully'
  description = this.label
  fetching = false
  clipped = '';
  generated = false

  http = new Http({
    success: () => this.$emit('success', true),
    failed: () => this.$emit('success', false),
    unauthorized: () => {
      window.location.href = '/login'
    }
  })

  copyAsync () {
    if (this.generated) {
      return this.clipboard(this.clipped)
    }

    this.fetching = true
    this.http.post(this.url, {})
      .then((res) => {
        const noDuplicates = [...new Set(res.titles_too_long)]
        if (res.titles_too_long.length > 0) {
          window.alert('[WARNING] Some ' + noDuplicates + ' Headlines are too long')
        }
        this.clipped = res.data
        this.clipboard(this.clipped)
        this.generated = true
        this.fetching = false
      })
  }
}
