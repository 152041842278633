



































import { Vue, Component, Prop } from 'vue-property-decorator'
import Searchbox from '../../form/Searchbox.vue'
import Label from '../../base/Label.vue'
import SubTitle from '../../base/SubTitle.vue'
import Badge from '../../base/Badge.vue'
import HttpGet from '../../logic/HttpGet.vue'
import TreeView from '../../base/TreeView.vue'

interface TreeItem {
  id: string;
}

@Component({
  components: { HttpGet, SubTitle, Searchbox, Label, Badge, TreeView }
})
export default class SearchSelect extends Vue {
  @Prop() label!: string
  @Prop() title!: string
  @Prop() url!: string
  @Prop() name!: string
  @Prop({ default: '' }) validation!: string
  @Prop({ default: '' }) tagUrl!: string
  @Prop() value!: Array<object>

  search = []
  tags = []
  searchTerm = ''
  selected = this.value

  format (text) {
    return text.replace('/', '')
  }

  select (result) {
    const current = this.selected.find((res: TreeItem) => res.id === result.id)

    if (current === undefined) {
      this.selected = this.selected.concat(result)
    }

    this.$emit('input', this.selected)
  }

  remove (result) {
    this.selected = this.selected.filter((res: TreeItem) => res.id !== result.id)
    this.$emit('input', this.selected)
  }
}
