
export const textRoutes = [
  {
    path: '/texts',
    meta: { title: 'Texts' },
    name: 'Texts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/text/Index.vue')
  }
]
