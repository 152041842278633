






import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from './Icon.vue'

@Component({
  components: { Icon }
})
export default class Empty extends Vue {
  @Prop({ default: 'No results found' }) message!: string;
}
