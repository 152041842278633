















import { Component, Prop, Vue } from 'vue-property-decorator'
import LoaderIcon from '../svg/LoaderIcon.vue'

@Component({
  components: { LoaderIcon }
})
export default class ActionButton extends Vue {
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: 'primary' }) variant!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) spin!: boolean;
  @Prop({ default: 'edit' }) action!: string

  isLoading = false;

  type () {
    switch (this.action) {
      case 'button':
        return ['text-primary', 'hover:bg-primary-lightest', 'active:bg-primary-lightest', 'active:text-whitish']
      case 'edit':
        return ['text-primary', 'hover:bg-primary-lightest', 'active:bg-secondary-lighter', 'active:text-whitish']
      case 'delete':
        return ['text-red-dark', 'hover:bg-red-light', 'active:bg-red-dark', 'active:text-white']
      default:
        return []
    }
  }
}
