















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Paginatable } from '../../interfaces/Paginatable'

@Component
export default class Paginator extends Vue {
  @Prop() meta!: Paginatable | undefined
  @Prop({ default: -1 }) limit!: number

  pageRange (): Array<string> {
    if (this.meta === undefined) {
      return ['0']
    }

    if (this.limit === -1) {
      return ['0']
    }

    if (this.limit === 0) {
      return [this.meta.last_page.toString()]
    }

    const current = this.meta.current_page
    const lastPage = this.meta.last_page
    const delta = this.limit
    const left = current - delta
    const right = current + delta + 1
    const range = []
    const pages: Array<string> = []

    let l = 0

    for (let index = 1; index <= lastPage; index++) {
      if (index === 1 || index === lastPage || (index >= left && index < right)) {
        range.push(index)
      }
    }

    range.forEach(function (i) {
      if (l) {
        if (i - l === 2) {
          pages.push((l + 1).toString())
        } else if (i - l !== 1) {
          pages.push('...')
        }
      }
      pages.push(i.toString())
      l = i
    })
    return pages
  }
}
