























import { Vue, Component, Prop } from 'vue-property-decorator'
import Action from '../data/Action.vue'

@Component({ components: { Action } })
export default class TagBox extends Vue {
  @Prop() label!: string
  @Prop() validation!: object
  @Prop() value!: Array<string>
  @Prop({ default: false }) inline!: boolean

  tags = this.value
  showInput = false
  newTag = ''

  classes () {
    if (this.inline) {
      return ['outline-none']
    }

    const field = ['p-1 px-2 block border border-gray rounded rounded-md outline-none group-hover:border-secondary-lighter']
    return (this.validation) ? field.concat('border-red') : field
  }

  remove (tag) {
    this.tags = this.tags.filter((currentTag) => !(currentTag === tag))
    this.$emit('input', this.tags)
  }

  add () {
    this.tags = this.tags.concat(this.newTag)
    this.newTag = ''
    this.$emit('input', this.tags)
  }
}
