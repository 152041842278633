






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Action extends Vue {
  @Prop() icon: string
  @Prop({ default: null }) label: string
  @Prop({ default: 'edit' }) action!: string

  type () {
    switch (this.action) {
      case 'button':
        return ['text-primary', 'hover:bg-primary-lightest', 'active:bg-primary-lightest', 'active:text-whitish']
      case 'edit':
        return ['text-primary', 'hover:bg-primary-lightest', 'active:bg-secondary-lighter', 'active:text-whitish']
      case 'delete':
        return ['text-red-dark', 'hover:bg-red-light', 'active:bg-red-dark', 'active:text-white']
      default:
        return []
    }
  }
}
