














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class OverlayLink extends Vue {
  @Prop() wrapper!: string
  @Prop() link!: string
  @Prop() icon!: string
  @Prop() secondIcon!: string
  @Prop() name!: string

  toggle () {
    const responsiveDiv = this.$el.closest('div').parentElement.parentElement.nextSibling as HTMLElement
    if (responsiveDiv.style.display !== 'none') {
      responsiveDiv.style.display = 'none'
    } else {
      responsiveDiv.style.display = 'inline'
    }
  }
}
