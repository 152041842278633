










import { Vue, Component, Prop } from 'vue-property-decorator'
import { Clip } from '../../mixins/Clip'

@Component({ mixins: [Clip] })
export default class Copy extends Vue {
  @Prop() wrapper!: string
  @Prop() data!: string
  @Prop() label!: string
  @Prop({ default: false }) nested: boolean

  message = 'Copied data successfully'
  description = this.label
}
