
export interface Notifiable {
    id?: number;
    message: string;
    description?: string;
    type?: number;
}

export enum NotifiableType {
    Message = 1,
    Error = 2
}
