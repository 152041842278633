















import { Http } from '../../classes/Http'
import Loader from '../base/Loader.vue'
import Error from '../base/Error.vue'

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

interface Url {
  key: number;
  link: string;
}

@Component({
  components: { Loader, Error }
})
export default class HttpGet extends Vue {
  @Prop({ default: '' }) url!: string | Array<Url>
  @Prop({ default: false }) smallLoader!: boolean
  @Prop({ default: false }) noLoader!: boolean
  @Prop() value!: any
  @Prop({ default: false }) force!: boolean
  @Prop({ default: false }) loadOnView!: boolean

  data: Array<Url> = [];

  times = 0;
  isFetching = false;
  throttle = null;
  hasFailed = false
  http = new Http({
    success: () => this.$emit('success', true),
    failed: this.failed,
    unauthorized: () => {
      window.location.href = 'login'
      document.dispatchEvent(new CustomEvent('unauthorized', { detail: true }))
    }
  })

  mounted () {
    if (this.value === undefined || this.value.data === undefined || this.value.data.length === 0) {
      this.fetch()
    } else if (this.force) {
      this.fetch()
    } else {
      this.isFetching = false
    }
  }

  failed () {
    this.hasFailed = true
    this.$emit('success', false)
  }

  fetch () {
    this.isFetching = true
    this.hasFailed = false
    this.data = []

    if (!Array.isArray(this.url)) {
      return this.http.fetch(this.url)
        .then((data: any) => {
          this.data = data
          this.isFetching = false

          this.$emit('input', this.data)
        })
    }

    for (const url of this.url) {
      this.http.fetch(url.link)
        .then((data: any) => {
          this.data[url.key] = data
        })
    }

    this.$forceUpdate()
    this.$emit('input', this.data)
  }

  @Watch('url')
  throttleFetch () {
    clearTimeout(this.throttle)
    this.throttle = setTimeout(() => this.fetch(), 500)
  }

  @Watch('times')
  watchTimes () {
    if (this.url === undefined) {
      return
    }

    if (this.times === this.url.length) {
      this.isFetching = false
    }
  }
}
