
















import { Vue, Component } from 'vue-property-decorator'
import { DropdownBehaviour, HttpPost } from '../lib/mavejs'
import { url } from '@/functions'

@Component({
  components: { HttpPost },
  mixins: [DropdownBehaviour],
  methods: { url: url }
})
export default class User extends Vue {
  isOpen = false
  userId = 0
  username = ''

  $refs: {
    user: HttpPost;
  }

  mounted () {
    const user = JSON.parse(localStorage.getItem('user'))

    this.userId = user.id
    this.username = user.name
  }

  logout () {
    this.$refs.user.post().then(() => {
      localStorage.removeItem('user')
      this.$router.push('/login')
    })
  }
}
