















import { Component, Vue, Prop } from 'vue-property-decorator'
import { DropdownMenu, DropdownMenuItem, Button, DropdownBehaviour } from '../lib/mavejs'
import User from './User.vue'

@Component({
  components: { DropdownMenuItem, DropdownMenu, Button, User },
  mixins: [DropdownBehaviour]
})
export default class Navbar extends Vue {
  @Prop() heading!: string;
  @Prop() navOpen!: boolean;

  isMenuOpen = false;

  get title (): string {
    return this.heading
  }
}
