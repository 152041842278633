import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { languageRoutes } from '@/router/language'
import { textRoutes } from '@/router/text'
import { campaignRoutes } from '@/router/campaign'
import { geoRoutes } from '@/router/geo'
import { authRoutes } from '@/router/auth'
import { audienceRoutes } from '@/router/audience'
import { topicRoutes } from '@/router/topic'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/campaigns'
  },
  {
    path: '*',
    redirect: '404'
  },
  {
    path: '/404',
    name: 'Not Found',
    component: () => import('../views/error/NotFound.vue')
  },
  ...audienceRoutes,
  ...topicRoutes,
  ...authRoutes,
  ...geoRoutes,
  ...textRoutes,
  ...languageRoutes,
  ...campaignRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
