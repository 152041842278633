

















import { Component, Prop, Vue } from 'vue-property-decorator'
import LoaderIcon from '../svg/LoaderIcon.vue'

@Component({
  components: { LoaderIcon }
})
export default class Button extends Vue {
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: 'primary' }) variant!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) spin!: boolean;

  isLoading = false;

  get type (): Array<string> {
    switch (this.variant) {
      case 'primary':
        return ['bg-primary', 'text-white', 'hover:bg-primary-light', 'active:bg-primary-dark']
      case 'danger':
        return ['bg-red', 'text-white', 'hover:bg-red-dark']
      case 'warning':
        return ['bg-yellow-light', 'text-yellow-dark', 'hover:bg-yellow', 'hover:text-yellow-dark']
      case 'secondary':
        return ['bg-secondary-light', 'text-secondary', 'hover:bg-secondary-lightest', 'active:text-white', 'active:bg-secondary-lighter']
      default:
        return ['hover:bg-gray-light']
    }
  }
}
