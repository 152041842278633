





import { Component, Vue, Prop } from 'vue-property-decorator'
import TreeViewItem from './TreeViewItem.vue'

@Component({
  components: { TreeViewItem }
})
export default class TreeView extends Vue {
  @Prop() items!: Array<object>
  @Prop() name!: string

  mounted () {
    document.addEventListener('treeview:' + this.name + ':selected', (event: any) => this.handle(event.detail), false)
  }

  handle (parentItem) {
    return this.$emit('selected', parentItem)
  }
}
