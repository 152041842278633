









import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Check from './Check.vue'

@Component({ components: { Check } })
export default class CheckGroup extends Vue {
  @Prop() value!: Array<string>
  @Prop() items!: Array<object>
  @Prop({ default: 'col' }) flow!: string
  @Prop({ default: '' }) validation!: string

  selected = {}
  isLoaded = false

  mounted () {
    const select = {}

    for (const item of this.value) {
      select[item] = true
    }

    this.selected = select
    this.isLoaded = true
  }

  @Watch('selected', { deep: true })
  change () {
    const values = Object.keys(this.selected)
      .filter(key => this.selected[key])
      .map(String)

    this.$emit('input', values)
  }
}
