

















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DropdownBehaviour } from '../../mixins/DropdownBehaviour'
import SubTitle from '../../components/base/SubTitle.vue'
import Badge from '../../components/base/Badge.vue'

@Component({
  components: { Badge, SubTitle }
})
export default class Nested extends Mixins(DropdownBehaviour) {
  @Prop() label!: string;
  @Prop({ default: '' }) extra!: string

  isOpen = false
}
