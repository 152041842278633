import { Vue, Component } from 'vue-property-decorator'

@Component
export class DropdownBehaviour extends Vue {
    isOpen = false
    isOut = true

    mounted () {
      window.addEventListener('click', () => {
        if (this.isOut) {
          this.isOpen = false
          this.isOut = false
        }
      })
    }
}
