







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Notification extends Vue {
  @Prop() message!: string;
  @Prop({ default: '' }) description!: string;
}
