











import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { format } from 'date-fns'

@Component({
  components: { DatePicker },
  methods: { format: format }
})
export default class Datefield extends Vue {
  @Prop() label!: string
  @Prop() name!: string
  @Prop() value!: string
  @Prop() placeholder!: string
  @Prop() description!: string
  @Prop({ default: false }) noBorder!: boolean
  @Prop({ default: '' }) validation!: string

  date = new Date(this.value)

  @Watch('date')
  emitDate () {
    this.$emit('input', this.date)
  }
}
