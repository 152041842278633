















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import HttpGet from '../logic/HttpGet.vue'
import CheckGroup from './CheckGroup.vue'
import { DropdownBehaviour } from '../../mixins/DropdownBehaviour'

@Component({ components: { CheckGroup, HttpGet }, mixins: [DropdownBehaviour] })
export default class CheckDropdown extends Vue {
  @Prop() value!: Array<string>
  @Prop() items: Array<object>
  @Prop() label!: string
  @Prop({ default: false }) dark!: boolean

  @Watch('value')
  valueChanged () {
    this.$emit('input', this.value)
  }
}
