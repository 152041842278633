





































import { Vue, Component, Prop } from 'vue-property-decorator'
import Card from '../../../components/base/Card.vue'
import { StepsPage } from '../../../interfaces/StepsPage'
import { StepsComponent } from '../../../interfaces/StepsComponent'

@Component({ components: { Card } })
export default class Steps extends Vue {
  @Prop() steps!: Array<StepsPage>
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() action!: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() store!: object
  @Prop({ default: 'submit' }) label!: string

  page = 0

  $refs: {
    component: StepsComponent;
  }

  paginate (index) {
    this.$refs.component.trigger().then(() => {
      if (this.$refs.component.isValid || this.page > index) {
        this.page = index
      }
    })
  }

  next () {
    this.$refs.component.trigger().then(() => {
      if (this.$refs.component.isValid && (this.steps.length - 1) === this.page) {
        return this.action()
      }

      if (this.$refs.component.isValid) {
        this.page++
      }
    })
  }

  prev () {
    this.page--
  }
}
