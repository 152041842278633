import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { setLocale } from 'yup'
import Token from '@/classes/Token'
import Sentry from '@sentry/vue'

import '@/assets/styles/main.css'

Token.prepareToken()

Vue.config.productionTip = false

setLocale({
  boolean: {
    isValue: 'Must be enabled or disabled'
  }
})

window.addEventListener('beforeunload', (event) => {
  event.preventDefault()
  event.returnValue = 'Are you sure you want to refresh the browser? You will lose all data'
})

new Vue({
  el: '#app',
  router: router,
  store: store,
  render: h => h(App)
}).$mount('#app')

// Sentry.init({
//   Vue: Vue,
//   dsn: 'https://c176793da0fb43a598bfe0baeefc345a@o527014.ingest.sentry.io/5642803'
// })
