










import { Vue, Component, Prop } from 'vue-property-decorator'
import Edit from '../base/Edit.vue'

@Component({
  components: { Edit }
})
export default class FieldEdit extends Vue {
  @Prop() value!: string;

  currentValue = this.value
  isInEditingMode = false

  toggleEditMode () {
    this.isInEditingMode = !this.isInEditingMode

    if (this.isInEditingMode === false) {
      this.$emit('edit', this.currentValue)
    }
  }
}
