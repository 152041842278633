





import { Vue, Component, Prop } from 'vue-property-decorator'
import HttpPost from './HttpPost.vue'
import Button from '../form/Button.vue'

@Component({
  components: { HttpPost, Button }
})
export default class PostButton extends Vue {
  @Prop() label!: string
  @Prop() url!: string
  @Prop({ default: 'primary' }) variant!: string;
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: '' }) body!: any

  isSuccessFull = true
  response = []

  $refs: {
    button: Button;
    http: HttpPost;
  }

  handleResponse (fetch) {
    this.$refs.button.isLoading = fetch.isFetching
    this.response = fetch.data

    this.$emit('response', this.response)
  }

  handleSuccess (success: boolean) {
    this.isSuccessFull = success

    this.$emit('success', this.response)
  }

  post () {
    this.$refs.http.post()
  }
}
