import { format, startOfTomorrow } from 'date-fns'

export const initialVideoForm = {
  campaign: {
    name: '',
    description: '',
    cpa: 30,
    budget: 150,
    maxCpc: null,
    bidStrategy: 'Target CPA',
    date: format(startOfTomorrow(), 'yyyy-MM-dd'),
    campaignType: null,
    overwriteCampaigns: null,
    overwriteAdgroups: null,
    xreplace: null,
    creator: null
  },
  targeting: {
    audiencesGrouping: 1,
    audiences: [],
    topicsGrouping: 0,
    topics: [],
    devicesGrouping: 1,
    devices: ['computers', 'phones', 'tablets'],
    networks: null,
    householdIncomesGrouping: 1,
    householdIncomes: ['Top 10%', '11-20%', '21-30%', '31-40%', '41-50%', 'Lower 50%', 'Unknown'],
    timezones: 1,
    inventoryType: 0,
    genderGrouping: 1,
    genders: ['female', 'male'],
    ageGrouping: 1,
    ages: ['45-54', '55-64', '65-up'],
    parentalGrouping: 1,
    parentals: ['parent', 'Not a parent', 'unknown']
  },
  geos: [],
  settings: {
    amount: 3,
    discountAmount: 0,
    cta: null,
    description: null,
    exportPositive: 0,
    siteLinks: 0,
    placementexclusion: false
  },
  ads: null
}

export const initialSearchForm = {
  campaign: {
    name: '',
    description: '',
    cpa: 25,
    budget: 150,
    maxCpc: 0,
    bidStrategy: 'Target CPA',
    campaignType: 2,
    type: 2,
    creator: null,
    date: format(startOfTomorrow(), 'yyyy-MM-dd')
  },
  targeting: {
    audiencesGrouping: 1,
    audiences: [],
    topicsGrouping: 0,
    topics: [],
    devicesGrouping: 1,
    devices: ['computers', 'phones', 'tablets', 'tv'],
    networks: ['Search partners'],
    householdIncomesGrouping: 1,
    householdIncomes: ['Top 10%', '11-20%', '21-30%', '31-40%', '41-50%', 'Lower 50%', 'Unknown'],
    timezones: 0,
    inventoryType: 1,
    genderGrouping: 1,
    genders: ['female', 'male', 'unknown'],
    ageGrouping: 1,
    ages: ['18-24', '25-34', '35-44', '45-54', '55-64', '65-up', 'unknown'],
    parentalGrouping: 1,
    parentals: ['parent', 'Not a parent', 'unknown']
  },
  geos: [],
  settings: {
    amount: 1,
    discountAmount: 0,
    cta: null,
    description: null,
    exportPositive: 0,
    siteLinks: [],
    headlines: [],
    callOutExtensions: [],
    snippets: [],
    snippetHeader: 'Amenities',
    urlPath: [],
    keywordsPositive: null,
    keywordsNegative: null,
    keywordsTypePositive: 'Phrase',
    keywordsTypeNegative: 'Phrase',
    keywordPresets: [],
    keywordPresetsType: 'Phrase',
    keywordPresetsReplace: null,
    mainUrl: null,
    placementexclusion: false
  },
  ads: null
}

export const Campaigns = {
  state: () => ({
    data: [],
    compare: {
      data: []
    },
    form: {
      videos: JSON.parse(JSON.stringify(initialVideoForm)),
      videosEdit: [],
      search: JSON.parse(JSON.stringify(initialSearchForm)),
      searchEdit: []
    }
  }),
  mutations: {
    changeAds (state, ads) {
      state.form.videos.ads = ads
    },
    clearForm (state, type) {
      if (type.id === undefined) {
        // eslint-disable-next-line eqeqeq
        if (type.name == 'videosEdit' || type.name == 'videos') {
          state.form[type.name] = JSON.parse(JSON.stringify(initialVideoForm))
          // eslint-disable-next-line eqeqeq
        } else if (type.name == 'search') {
          state.form[type.name] = JSON.parse(JSON.stringify(initialSearchForm))
        } else {
          state.form[type.name][type.id] = undefined
        }
      }
    }
  }
}
