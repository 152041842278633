
export const campaignRoutes = [
  {
    path: '/campaigns',
    meta: { title: 'Campaigns' },
    name: 'Campaigns',
    props: route => ({ refresh: route.query.refresh }),
    component: () => import('../views/campaigns/Index.vue')
  },
  {
    path: '/campaigns/create-search',
    meta: { title: 'Create Search Campaign' },
    name: 'Create Search Campaign',
    component: () => import('../views/campaigns/search/CreateSearchCampaign.vue')
  },
  {
    path: '/campaigns/create',
    meta: { title: 'Create Campaign' },
    name: 'Create Video Campaign',
    component: () => import('../views/campaigns/video/CreateCampaign.vue')
  },
  {
    path: '/campaigns/:id/edit',
    meta: { title: 'Edit Video Campaign' },
    name: 'Edit Video Campaign',
    component: () => import('../views/campaigns/video/EditCampaign.vue')
  },
  {
    path: '/campaigns/:id/edit-search',
    meta: { title: 'Edit Search Campaign' },
    name: 'Edit Search Campaign',
    component: () => import('../views/campaigns/search/EditSearchCampaign.vue')
  },
  {
    path: '/campaigns/:id/compare',
    meta: { title: 'Compare Names' },
    name: 'Compare Names',
    component: () => import('../views/campaigns/overwrites/CompareNames.vue')
  }
]
