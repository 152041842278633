



import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DataTableColumn extends Vue {
  @Prop({ default: 1 }) colspan!: number
}
