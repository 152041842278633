export interface HttpCallbacks {
    success: Function;
    failed: Function;
    unauthorized: Function;
}

declare global {
    interface Window { token: string }
}

export class Http {
    callback: HttpCallbacks

    constructor (callback: HttpCallbacks) {
      this.callback = callback
    }

    response (res: Response) {
      switch (res.status) {
        case 201:
          this.callback.success()
          return res.json()
        case 200:
          this.callback.success()
          return res.json()
        case 401:
          this.callback.unauthorized()
          return res.json()
        case 403:
          this.callback.unauthorized()
          return res.json()
        case 404:
          this.callback.failed()
          return res.json()
        case 422:
          this.callback.failed()
          return res.json()
        case 500:
          this.callback.failed()
          return res.json()
        default:
          return res.json()
      }
    }

    headers () {
      const token = window.token === undefined ? localStorage.getItem('token') : window.token

      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token ? 'Bearer ' + token : ''
      }
    }

    fetch (endpoint: string) {
      const options = { headers: this.headers() }

      return fetch(endpoint, options)
        .then(res => this.response(res))
    }

    post (endpoint: string, body: object) {
      const options = { headers: this.headers(), method: 'POST', body: JSON.stringify(body) }

      return fetch(endpoint, options)
        .then(res => this.response(res))
    }

    delete (endpoint: string) {
      const options = { headers: this.headers(), method: 'DELETE' }

      return fetch(endpoint, options)
        .then(res => this.response(res))
    }
}
