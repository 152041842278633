

















import { Vue, Component, Prop } from 'vue-property-decorator'
import Modal from './Modal.vue'
import HttpDelete from '../../logic/HttpDelete.vue'
import Button from '../../form/Button.vue'

@Component({
  components: { Modal, HttpDelete, Button }
})
export default class DeleteConfirmationModal extends Vue {
  @Prop() message!: string
  @Prop() endpoint!: string

  $refs: {
    modal: Modal;
  }

  open () {
    this.$refs.modal.isOpen = true
  }

  done () {
    this.$refs.modal.isOpen = false
    this.$emit('deleted', true)
  }
}
