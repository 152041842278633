









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LoaderIcon extends Vue {
  @Prop() color!: string;

  selectColor () {
    switch (this.color) {
      case 'danger':
        return '#E50000'
      default:
        return '#1C7F9D'
    }
  }
}
