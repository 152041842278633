import Vue from 'vue'

export const Errors = Vue.extend({
  data () {
    return {
      isValid: false,
      errors: {}
    }
  },
  methods: {
    validate (errors: object) {
      this.errors = errors
      this.isValid = (Object.keys(this.errors).length === 0)
    },
    trigger () {
      return this.$refs.validator.trigger()
    }
  }
})
