
export const languageRoutes = [
  {
    path: '/languages',
    meta: { title: 'Languages' },
    name: 'Languages',
    component: () => import('../views/language/Index.vue')
  },
  {
    path: '/languages/create',
    meta: { title: 'Create Language' },
    name: 'Create Language',
    component: () => import('../views/language/CreateLanguage.vue')
  },
  {
    path: '/languages/:id/edit',
    meta: { title: 'Edit Language' },
    name: 'Edit Language',
    component: () => import('../views/language/EditLanguage.vue')
  }
]
