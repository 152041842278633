














import { Component, Vue, Prop } from 'vue-property-decorator'
import Tooltip from './Tooltip.vue'

@Component({
  components: { Tooltip }
})
export default class OverlayLink extends Vue {
  @Prop() wrapper!: string
  @Prop() link!: string
  @Prop() icon!: string
  @Prop({ default: '' }) tooltip!: string;
}
