import { Vue, Component } from 'vue-property-decorator'
import { Http } from '../classes/Http'

@Component
export class HttpMixin extends Vue {
  failed = false
  isRefreshing = false
  lastTimeRefreshed = ''

  http = new Http({
    success: () => {
      this.failed = false
    },
    failed: () => {
      this.failed = true
    },
    unauthorized: () => {
      document.dispatchEvent(new CustomEvent('unauthorized', { detail: true }))
    }
  })
}
