









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NotifyBadge extends Vue {
  @Prop() text!: string
  @Prop() show!: boolean
  @Prop({ default: 'left' }) position!: string
}
